import React from 'react'
import Routes from '../routes'

const App = () => (
    <div>
        <Routes />
    </div>
) 

export default App
